import {
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui-kraud/react';
import { FC } from 'react';
import { SubmitModalProps } from './submit-modal.types';

export const SubmitModal: FC<SubmitModalProps> = ({
	isOpen,
	onClose,
	onSubmit,
	text,
	title,
	closeBtnText,
	submitBtnText,
}) => {
	return (
		<Modal isOpen={isOpen} isCentered onClose={onClose} size="lg">
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>{title}</ModalHeader>
				<ModalCloseButton />
				<ModalBody>{text}</ModalBody>
				<ModalFooter>
					<Button variant="ghost" colorScheme="tertiary" onClick={onClose}>
						{closeBtnText}
					</Button>
					<Button variant="solid" mr={3} colorScheme="primary" onClick={onSubmit}>
						{submitBtnText}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
