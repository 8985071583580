import { FC, useImperativeHandle } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { NumCatalogFormProps } from './num-catalog-form.types';

import { CalendarSinglePicker, CustomSelect, Tooltips } from '@/shared';
import { CustomInput } from '@/shared/components/custom-input';

import styles from './num-catalog-form.module.scss';

export const NumCatalogForm: FC<NumCatalogFormProps> = ({ onSubmit, selectedNum = {}, reference }) => {
	const numValidationScheme = yup.object({
		num: yup
			.string()
			.matches(/^\d+$/, 'Поле должно содержать только числа')
			.required(Tooltips.PRODUCT_CATALAG_TEXT_NUM),
		symbol: yup.string().required(Tooltips.PRODUCT_CATALOG_TEXT),
		gost: yup.string().required(Tooltips.PRODUCT_CATALOG_TEXT),
		sortament: yup.string().required(Tooltips.PRODUCT_CATALOG_TEXT),
		gost_na_sortament: yup.string().required(Tooltips.PRODUCT_CATALOG_TEXT),
		number_of_melt: yup.string().required(Tooltips.PRODUCT_CATALOG_TEXT),
		date_of_manufacture: yup.string().notRequired().default(null),
		standard_consumption_type: yup.string().required(Tooltips.SELECT_TEXT),
		quantity: yup
			.number()
			.typeError(Tooltips.PRODUCT_CATALAG_TEXT_NUM)
			.required(Tooltips.PRODUCT_CATALAG_TEXT_NUM)
			.positive(Tooltips.PRODUCT_CATALAG_TEXT_NUM)
			.integer(Tooltips.PRODUCT_CATALAG_TEXT_NUM),
	});

	useImperativeHandle(reference, () => ({
		submitForm() {
			handleSubmit(onSubmit)();
		},
	}));
	const {
		control,
		handleSubmit,
		formState: { errors },
		getValues,
		setValue,
	} = useForm({
		defaultValues: {
			...selectedNum,
		},
		resolver: yupResolver(numValidationScheme),
	});

	return (
		<form onSubmit={handleSubmit(onSubmit)} className={styles['form']}>
			<Controller
				control={control}
				name="num"
				render={({ field, fieldState: { error } }) => (
					<CustomInput
						size="md"
						{...field}
						isInvalid={!field.value}
						label="НУМ"
						defaultValue={selectedNum?.num}
						value={field.value}
						styleTooltip={'num-catalog-tooltip'}
						showTooltip={!!errors.num?.message}
						tooltipContent={errors.num?.message}
					/>
				)}
			/>
			<Controller
				control={control}
				name="symbol"
				render={({ field, fieldState: { error } }) => (
					<CustomInput
						size="md"
						{...field}
						isInvalid={!field.value}
						label="Материал"
						defaultValue={selectedNum?.symbol}
						styleTooltip={'num-catalog-tooltip'}
						showTooltip={!!errors.symbol?.message}
						tooltipContent={errors.symbol?.message}
					/>
				)}
			/>
			<Controller
				control={control}
				name="gost"
				render={({ field, fieldState: { error } }) => (
					<CustomInput
						size="md"
						{...field}
						isInvalid={!field.value}
						label="Стандарт материала"
						defaultValue={selectedNum.gost}
						styleTooltip={'num-catalog-tooltip'}
						showTooltip={!!errors.gost?.message}
						tooltipContent={errors.gost?.message}
					/>
				)}
			/>
			<Controller
				control={control}
				name="sortament"
				render={({ field, fieldState: { error } }) => (
					<CustomInput
						size="md"
						{...field}
						isInvalid={!field.value}
						label="Сортамент"
						defaultValue={selectedNum.sortament}
						styleTooltip={'num-catalog-tooltip'}
						showTooltip={!!errors.sortament?.message}
						tooltipContent={errors.sortament?.message}
					/>
				)}
			/>
			<Controller
				control={control}
				name="gost_na_sortament"
				render={({ field, fieldState: { error } }) => (
					<CustomInput
						size="md"
						{...field}
						isInvalid={!field.value}
						label="Стандарт сортамента"
						defaultValue={selectedNum.gost_na_sortament}
						styleTooltip={'num-catalog-tooltip'}
						showTooltip={!!errors.gost_na_sortament?.message}
						tooltipContent={errors.gost_na_sortament?.message}
					/>
				)}
			/>
			<Controller
				control={control}
				name="standard_consumption_type"
				render={({ field, fieldState: { error } }) => (
					<CustomSelect
						allowClear={false}
						size="large"
						{...field}
						options={[
							{ value: 'meters', label: 'Метр' },
							{ value: 'square_meters', label: 'Метр квадратный' },
							{ value: 'kg', label: 'Килограмм' },
							{ value: 'grams', label: 'Грамм' },
						]}
						isInvalid={!field.value}
						showTooltip={!!errors.standard_consumption_type?.message}
						tooltipContent={errors.standard_consumption_type?.message}
						label="Единица нормы расхода"
						defaultValue={selectedNum.standard_consumption_type}
					/>
				)}
			/>
			<Controller
				control={control}
				name="quantity"
				render={({ field, fieldState: { error } }) => (
					<CustomInput
						size="md"
						{...field}
						isInvalid={!field.value || !!error?.message}
						label="Количество"
						defaultValue={selectedNum?.quantity}
						value={field.value}
						styleTooltip={'num-catalog-tooltip'}
						showTooltip={!!errors.quantity?.message}
						tooltipContent={errors.quantity?.message}
					/>
				)}
			/>
			<Controller
				control={control}
				name="certificate"
				render={({ field, fieldState: { error } }) => (
					<CustomInput
						size="md"
						{...field}
						isInvalid={!!error}
						label="Сертификат"
						defaultValue={selectedNum.certificate}
					/>
				)}
			/>
			<Controller
				control={control}
				name="batch_number"
				render={({ field, fieldState: { error } }) => (
					<CustomInput
						size="md"
						{...field}
						isInvalid={!!error}
						label="№ партии"
						defaultValue={selectedNum.batch_number}
					/>
				)}
			/>
			<Controller
				control={control}
				name="number_of_melt"
				render={({ field, fieldState: { error } }) => (
					<CustomInput
						size="md"
						{...field}
						isInvalid={!field.value}
						label="№ плавки"
						defaultValue={selectedNum.number_of_melt}
						styleTooltip={'num-catalog-tooltip'}
						showTooltip={!!errors.number_of_melt?.message}
						tooltipContent={errors.number_of_melt?.message}
					/>
				)}
			/>
			<Controller
				control={control}
				name="divisions"
				render={({ field, fieldState: { error } }) => (
					<CustomInput
						size="md"
						{...field}
						isInvalid={!!error}
						label="Мех. свойства"
						defaultValue={selectedNum.divisions}
					/>
				)}
			/>
			<Controller
				control={control}
				name="n_b"
				render={({ field, fieldState: { error } }) => (
					<CustomInput size="md" {...field} isInvalid={!!error} label="НВ" defaultValue={selectedNum.n_b} />
				)}
			/>
			<Controller
				control={control}
				name="o_v"
				render={({ field, fieldState: { error } }) => (
					<CustomInput label="σв" size="md" {...field} isInvalid={!!error} defaultValue={selectedNum.o_v} />
				)}
			/>
			<Controller
				control={control}
				name="date_of_manufacture"
				render={({ field, fieldState: { error } }) => (
					<CalendarSinglePicker
						// параметр maxDate, чтобы каленьдарь не был ограничен сегодняшним числом
						maxDate={new Date(2050, 1)}
						// ввиду странного поведения сброса значения на бэке, приходится использовать null и пустую строку для удаления даты в нуме / создания нума с датой
						onSelectDate={(date) => {
							setValue('date_of_manufacture', date ? date : null);
						}}
						onResetDate={() => {
							setValue('date_of_manufacture', '');
						}}
						defaultDate={getValues('date_of_manufacture') ?? null}
						label="Дата изготовления"
					/>
				)}
			/>
			<Controller
				control={control}
				name="valid_until"
				render={({ field, fieldState: { error } }) => (
					<CalendarSinglePicker
						maxDate={new Date(2050, 1)}
						// ввиду странного поведения сброса значения на бэке, приходится использовать null и пустую строку для удаления даты в нуме / создания нума с датой
						onSelectDate={(date) => {
							setValue('valid_until', date ? date : null);
						}}
						onResetDate={() => {
							setValue('valid_until', '');
						}}
						defaultDate={getValues('valid_until') ?? null}
						label="Годен до"
					/>
				)}
			/>
			<Controller
				control={control}
				name="validity"
				render={({ field, fieldState: { error } }) => (
					<CustomInput
						size="md"
						{...field}
						isInvalid={!!error}
						label="Годность"
						defaultValue={selectedNum.validity}
					/>
				)}
			/>
			<Controller
				control={control}
				name="comment"
				render={({ field, fieldState: { error } }) => (
					<CustomInput
						size="md"
						{...field}
						isInvalid={!!error}
						label="Комментарий"
						defaultValue={selectedNum.comment}
					/>
				)}
			/>
		</form>
	);
};
