import { clsx } from 'clsx';
import dayjs from 'dayjs';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { matchPath } from 'react-router-dom';

import { NotFoundContentDropdown, showErrorToast, STANDARD_CONSUMPTION_TYPES } from '@/shared';
import { StandardConsumptionTypeEnum, TECH_CARD_NEW, UserRoles } from '@/shared/core';
import { useAppSelector } from '@/shared/state';
import { useGetNumsQuery, useGetProductsQuery } from '@/shared/state/api';
import { MainBlockTypes } from './main.types';

import { chakra, Flex, Input, Table, Td, Text, Th, Tr, useToast } from '@chakra-ui-kraud/react';
import { DatePicker, Select } from 'antd';
import _debounce from 'lodash/debounce';

import defaultStyles from '../../tech-card.module.scss';
import styles from './main.module.scss';
import { ITechCard, OVorDateSelectOption } from '../../../../shared/core/interfaces/tech-card-interfaces';
import { convertNumberToNumberStringWithComma } from '@/shared/core/utils/convert-string-to-numbet-string';

export const MainBlock: FC<MainBlockTypes> = ({ cardInfo, isEditable, focusedBlock, materials, setFocusedBlock }) => {
	const toast = useToast();

	const userRole = useAppSelector((state) => state.auth.userProfile?.role);

	const { control, setValue, getValues } = useFormContext<ITechCard>();
	const selectedProduct = useWatch({ name: 'product', control });

	const isCreationMode = !!matchPath(TECH_CARD_NEW, location.pathname) && !cardInfo?.number;
	const productRef = useRef<HTMLTableCellElement>(null);

	const [productsQuery, setProductsQuery] = useState('');
	const [numsQuery, setNumsQuery] = useState('');

	const { data: productsData, isFetching: isProductsDataFetching } = useGetProductsQuery({
		string_to_search: productsQuery,
	});
	const { data: numsData } = useGetNumsQuery({ string_to_search: numsQuery });

	const { products, SelectProducts } = useMemo(() => {
		return {
			products: productsData?.payload,
			SelectProducts: productsData?.payload?.map((product) => ({
				value: product.id,
				label: `${product.symbol || 'Нет обозначения'} (${product.name || '-'})`,
			})) || [{ value: 0, label: '' }],
		};
	}, [productsData]);
	const { nums, SelectNum } = useMemo(() => {
		return {
			nums: numsData?.payload,
			SelectNum: numsData?.payload?.map((num) => ({
				value: num.id,
				label: num.num,
			})),
		};
	}, [numsData]);

	const debouncedProductsSearch = _debounce((search) => setProductsQuery(search), 700);
	const debouncedNumsSearch = _debounce((search) => setNumsQuery(search), 700);

	const [OVorDateSelectOptions, setOVorDateSelectOptions] = useState<OVorDateSelectOption[]>(
		[
			(cardInfo?.num?.o_v || getValues('num.o_v')) && {
				value: 'o_v',
				label: cardInfo?.num?.o_v || getValues('num.o_v'),
			},
			(cardInfo?.num?.date_of_manufacture || getValues('num.date_of_manufacture')) && {
				value: 'date_of_manufacture',
				label: dayjs(cardInfo?.num?.date_of_manufacture || getValues('num.date_of_manufacture')).format(
					'DD.MM.YYYY',
				),
			},
			{ value: 'empty', label: '-' },
		].filter(Boolean) as OVorDateSelectOption[], // Удаляет undefined элементы
	);

	const handleProductSelect = (value: string | number) => {
		setValue('operations', []);
		const selectedProduct = products?.find((product) => product.id === Number(value));
		const selectedMaterial = materials?.find((material) => material.id === selectedProduct?.material_id);

		setValue(
			'product',
			{
				...selectedProduct,
				id: selectedProduct?.id || 0,
				symbol: selectedProduct?.symbol || '',
				extra_symbol: selectedProduct?.extra_symbol || '',
				extra_name: selectedProduct?.extra_name,
				name: selectedProduct?.name || '-',
				approval_card: selectedProduct?.approval_card || '',
				extra_approval_card: selectedProduct?.extra_approval_card || '',
			},
			{ shouldValidate: true },
		);

		setValue(
			'product.material',
			{
				id: selectedMaterial?.id || 0,
				symbol: selectedMaterial?.symbol || '-',
				gost: selectedMaterial?.gost || '-',
				sortament: selectedMaterial?.sortament || '-',
				gost_na_sortament: selectedMaterial?.gost_na_sortament || '-',
			},
			{ shouldValidate: true },
		);

		setValue(
			'num',
			{
				id: undefined,
				num: undefined,
				number_of_melt: '',
				o_v: '',
				date_of_manufacture: '',
				symbol: '',
				sortament: '',
				gost: '',
				gost_na_sortament: '',
				o_v_or_date_of_manufacture: 'empty',
				standard_consumption_type: undefined,
			},
			{ shouldValidate: true },
		);
		setValue(
			'plan',
			{
				NR_grams: '',
				NR_kg: '',
				NR_square_meters: '',
				NR_meters: '',
			},
			{
				shouldValidate: true,
			},
		);

		setNumsQuery('');

		const sortedOperationsTypes = [...(selectedProduct?.operation_types ?? [])]?.sort(
			(a, b) => a.step_id - b.step_id,
		);
		sortedOperationsTypes?.forEach((operation, index) => {
			setValue(`operations.${index}.step_id`, operation.step_id);
			setValue(`operations.${index}.name`, operation.name);
		});
	};

	const handleNumSelect = useCallback(
		(value: number) => {
			const selectedNum = nums?.find((num) => num.id === value);

			if (selectedNum) {
				if (
					selectedNum.standard_consumption_type !== 'old' &&
					!selectedProduct[
						`standard_consumption_${selectedNum.standard_consumption_type}` as
							| `standard_consumption_${Exclude<StandardConsumptionTypeEnum, 'old'>}`
					]
				) {
					showErrorToast(toast, {
						title: 'Не подходящий материал',
						description: `Вы не можете выбрать этот материал потому что в детали не указано норма расхода на ${
							STANDARD_CONSUMPTION_TYPES[selectedNum.standard_consumption_type!]
						}`,
					});
					return;
				}

				if (selectedNum.standard_consumption_type === 'old') {
					setValue(
						'plan',
						{
							NR_kg: convertNumberToNumberStringWithComma(selectedProduct?.standard_consumption_kg),
							NR_meters: convertNumberToNumberStringWithComma(
								selectedProduct?.standard_consumption_meters,
							),
						},
						{ shouldValidate: true },
					);
				} else {
					setValue(
						'plan',
						{
							[`NR_${selectedNum.standard_consumption_type}`]: convertNumberToNumberStringWithComma(
								selectedProduct[
									`standard_consumption_${selectedNum.standard_consumption_type}` as
										| `standard_consumption_${Exclude<StandardConsumptionTypeEnum, 'old'>}`
								],
							),
						},
						{ shouldValidate: true },
					);
				}

				const nextOVorDateSelectOptions = [
					selectedNum?.o_v && { value: 'o_v', label: selectedNum?.o_v },
					selectedNum?.date_of_manufacture && {
						value: 'date_of_manufacture',
						label: selectedNum?.date_of_manufacture,
					},
					{ value: 'empty', label: '-' },
				].filter(Boolean) as OVorDateSelectOption[];

				setOVorDateSelectOptions(nextOVorDateSelectOptions);
				setValue(
					'num',
					{
						id: selectedNum?.id || 0,
						num: selectedNum?.num,
						number_of_melt: selectedNum?.number_of_melt || '-',
						o_v: selectedNum?.o_v || '',
						date_of_manufacture: selectedNum?.date_of_manufacture || '',
						symbol: selectedNum?.symbol || '-',
						sortament: selectedNum?.sortament || '-',
						gost: selectedNum?.gost || '-',
						gost_na_sortament: selectedNum?.gost_na_sortament || '-',
						o_v_or_date_of_manufacture: nextOVorDateSelectOptions[0].value,
						standard_consumption_type: selectedNum?.standard_consumption_type,
					},
					{ shouldValidate: true },
				);
			}
		},
		[nums, selectedProduct, toast, setValue],
	);

	useEffect(() => {
		if (productRef && productRef?.current && isCreationMode) {
			(productRef.current as HTMLElement).querySelectorAll('input')[0].focus();
		}
	}, [productRef?.current]);

	return (
		<Flex className={styles['main-block']}>
			<Flex direction="column">
				<Flex
					marginTop={'-1px'}
					className={clsx(
						focusedBlock?.block === 'main' &&
							focusedBlock.focusedBy === 'tab' &&
							!focusedBlock.atCurrentBlock &&
							defaultStyles.focused,
					)}
				>
					<Table
						marginRight={'-1px'}
						onFocus={() => setFocusedBlock && setFocusedBlock('main')}
						className={isEditable ? styles['edit-table'] : ''}
					>
						<Tr>
							{/* значение ширины колонки "Маршрутная карта" подобрано вручную для предпросмотра печати */}
							<Th minW="240px" maxW="240px" w="240px" className={styles['without_border_bottom']}>
								Маршрутная карта
							</Th>
							<Th minW="317px" maxW="317px" w="317px" className={styles['without_border_bottom']}>
								Обозначение
							</Th>
						</Tr>
						<Tr>
							<Td>
								{isCreationMode ? (
									<chakra.div display="flex" alignItems="center">
										<chakra.div flex={0.3}>
											<Controller
												name="symbol"
												control={control}
												render={({ field: { onChange, value }, fieldState: { error } }) => {
													return (
														<Input
															flex={0.5}
															tabIndex={1}
															isInvalid={!!error}
															onChange={(e) => {
																onChange(e.target.value.substring(0, 2));
															}}
															value={value}
															name="symbol"
														/>
													);
												}}
											/>
										</chakra.div>
										-
										<chakra.div flex={1} display="flex" alignItems="center">
											<chakra.p
												style={{
													color: '#A0AEC0',
													fontSize: '10px',
													paddingLeft: '6px',
													textAlign: 'left',
												}}
											>
												Номер будет сформирован после создания
											</chakra.p>
										</chakra.div>
									</chakra.div>
								) : (
									<>
										<Flex
											textAlign={'left'}
											alignItems={'center'}
											className={clsx(styles['print_bold_font'], styles['card-number-wrapper'])}
										>
											<span
												style={{
													marginRight: isEditable ? '36px' : '30px',
													marginLeft: isEditable ? '6px' : '0px',
												}}
											>
												<sup>№</sup>
											</span>

											{isEditable ? (
												<>
													<Controller
														name="symbol"
														control={control}
														render={({
															field: { onChange, value },
															fieldState: { error },
														}) => {
															return (
																<Input
																	flex={1}
																	tabIndex={1}
																	isInvalid={!!error}
																	onChange={onChange}
																	value={value}
																	name="symbol"
																	whiteSpace="36px"
																	maxWidth="36px"
																	maxLength={2}
																/>
															);
														}}
													/>
													-
													<Controller
														name="number"
														control={control}
														render={({
															field: { onChange, value },
															fieldState: { error },
														}) => {
															return (
																<Input
																	flex={1}
																	tabIndex={1}
																	isInvalid={!!error}
																	onChange={onChange}
																	value={value}
																	name="number"
																/>
															);
														}}
													/>
												</>
											) : cardInfo?.number && cardInfo?.symbol ? (
												`${cardInfo.symbol}-${cardInfo.number}`
											) : (
												cardInfo?.number
											)}
										</Flex>
									</>
								)}
							</Td>
							<Td ref={productRef}>
								{isEditable && isCreationMode ? (
									<Controller
										name="product.symbol"
										control={control}
										render={({ field: { onChange, value }, fieldState: { error } }) => (
											<Select
												status={error ? 'error' : ''}
												tabIndex={1}
												showSearch
												placeholder="Поиск..."
												optionFilterProp="children"
												onChange={(e) => {
													onChange(e);
													handleProductSelect(e);
												}}
												filterOption={() => true}
												onSearch={(search) => debouncedProductsSearch(search)}
												value={value}
												options={productsQuery ? SelectProducts : []}
												style={{
													width: '100%',
													maxWidth: '320px',
													boxShadow: error?.message ? '0 0 0 1px #fc5e6e' : '',
												}}
												className={styles['custom-select']}
												notFoundContent={
													<NotFoundContentDropdown
														isFetchingNaming={isProductsDataFetching}
														alertName={'Введите обозначение'}
													/>
												}
											/>
										)}
									/>
								) : (
									<Text
										className={clsx(styles['text-in-cell'], styles['print_bold_font'])}
										maxWidth="300px"
									>
										{cardInfo?.product.symbol}
									</Text>
								)}
							</Td>
						</Tr>
						<Tr>
							<Td textAlign={'left'}>
								<Flex
									textAlign={'left'}
									alignItems={'center'}
									gap={'10px'}
									className={styles['card-date-wrapper']}
								>
									<span
										style={{
											marginRight: isEditable ? '6px' : '0px',
											marginLeft: isEditable ? '6px' : '0px',
										}}
									>
										<sup>Дата</sup>
									</span>
									{isEditable || isCreationMode ? (
										<Controller
											name="created_at"
											control={control}
											render={({ field: { onChange, value }, fieldState: { error } }) => {
												return (
													<DatePicker
														name="created_at"
														tabIndex={1}
														defaultValue={dayjs(value)}
														disabledDate={(day) => day.isAfter(dayjs())}
														format={'DD.MM.YYYY'}
														onChange={(date) => onChange(date?.format('DD.MM.YYYY'))}
														status={error ? 'error' : ''}
													/>
												);
											}}
										/>
									) : cardInfo?.created_at ? (
										<span>{dayjs(cardInfo?.created_at).format('DD.MM.YYYY')}</span>
									) : null}
								</Flex>
							</Td>
							<Td>
								{isEditable ? (
									<Controller
										name="product.extra_name"
										control={control}
										render={({ field: { onChange, value }, fieldState: { error } }) => (
											<Input
												backgroundColor="transparent"
												tabIndex={2}
												isInvalid={!!error}
												onChange={onChange}
												value={value}
												name="product.extra_name"
											/>
										)}
									/>
								) : (
									<Text
										className={clsx(styles['text-in-cell'], styles['print_bold_font'])}
										maxWidth="300px"
									>
										{cardInfo?.product?.extra_name || getValues().product?.extra_name}
									</Text>
								)}
							</Td>
						</Tr>
					</Table>
					<Table
						onFocus={() => setFocusedBlock && setFocusedBlock('main')}
						className={clsx(isEditable ? styles['edit-table'] : '', styles['right-column-of-main'])}
					>
						<Tr>
							<Th className={styles['without_border_bottom']}>Наименование</Th>
						</Tr>
						<Tr h={'100%'}>
							<Td p="0 !important">
								{isEditable ? (
									<Controller
										name="product.name"
										control={control}
										render={({ field: { onChange, value }, fieldState: { error } }) => (
											<Input
												backgroundColor="transparent"
												isReadOnly={userRole === UserRoles.operator}
												className={
													userRole === UserRoles.operator ? styles['input-readonly'] : ''
												}
												tabIndex={2}
												onChange={onChange}
												value={value}
												name="product.name"
											/>
										)}
									/>
								) : (
									<Text className={clsx(styles['text-in-cell-wrap'], styles['print_bold_font'])}>
										{cardInfo?.product?.name}
									</Text>
								)}
							</Td>
						</Tr>
						{getValues('product.extra_symbol') && (
							<Tr>
								<Td p="0 !important">
									{isEditable ? (
										<Controller
											name="product.extra_symbol"
											control={control}
											render={({ field: { onChange, value }, fieldState: { error } }) => (
												<Input
													backgroundColor="transparent"
													isReadOnly={userRole === UserRoles.operator}
													className={
														userRole === UserRoles.operator ? styles['input-readonly'] : ''
													}
													tabIndex={2}
													onChange={onChange}
													value={value}
													name="product.extra_symbol"
												/>
											)}
										/>
									) : (
										<Text className={clsx(styles['text-in-cell-wrap'], styles['print_bold_font'])}>
											{cardInfo?.product?.extra_symbol}
										</Text>
									)}
								</Td>
							</Tr>
						)}
					</Table>
				</Flex>
				<Flex height={'100%'} marginTop={'-1px'}>
					<Table
						marginRight={'-1px'}
						onFocus={() => setFocusedBlock && setFocusedBlock('material')}
						className={clsx(
							focusedBlock?.block === 'material' &&
								focusedBlock.focusedBy === 'tab' &&
								!focusedBlock.atCurrentBlock &&
								defaultStyles.focused,
							isEditable && styles['edit-table'],
						)}
					>
						<Tr>
							<Th w="240px" verticalAlign="middle !important">
								Согласно техн. док.
							</Th>
							<Th verticalAlign="middle !important">Материал</Th>
							<Th verticalAlign="middle !important">Фактически выданный</Th>
						</Tr>
						<Tr>
							<Td style={{ textAlign: 'left' }}>
								{isEditable ? (
									<Controller
										name="product.material.symbol"
										control={control}
										render={({ field: { onChange, value }, fieldState: { error } }) => (
											<Input
												backgroundColor="transparent"
												isReadOnly={userRole === UserRoles.operator}
												className={
													userRole === UserRoles.operator ? styles['input-readonly'] : ''
												}
												tabIndex={2}
												onChange={onChange}
												value={value}
												name="product.material.symbol"
											/>
										)}
									/>
								) : (
									<Text
										className={clsx(styles['text-in-cell'], styles['print_bold_font'])}
										maxWidth="220px"
									>
										{cardInfo?.product.material?.symbol}
									</Text>
								)}
							</Td>
							<Th style={{ verticalAlign: 'middle', lineHeight: '1' }}>Обозначение</Th>
							<Td style={{ textAlign: 'left' }}>
								{isEditable ? (
									<Controller
										name="num.symbol"
										control={control}
										render={({ field: { onChange, value }, fieldState: { error } }) => (
											<Input
												backgroundColor="transparent"
												isReadOnly={userRole === UserRoles.operator}
												className={
													userRole === UserRoles.operator ? styles['input-readonly'] : ''
												}
												tabIndex={3}
												isInvalid={!!error}
												onChange={onChange}
												value={value}
												name="num.symbol"
											/>
										)}
									/>
								) : (
									<Text
										className={clsx(styles['text-in-cell'], styles['print_bold_font'])}
										maxWidth="170px"
									>
										{cardInfo?.num?.symbol}
									</Text>
								)}
							</Td>
						</Tr>
						<Tr>
							<Td style={{ textAlign: 'left' }}>
								{isEditable ? (
									<Controller
										name="product.material.gost"
										control={control}
										render={({ field: { onChange, value }, fieldState: { error } }) => (
											<Input
												backgroundColor="transparent"
												isReadOnly={
													userRole === UserRoles.operator ||
													userRole === UserRoles.senior_operator
												}
												className={
													userRole === UserRoles.operator ||
													userRole === UserRoles.senior_operator
														? styles['input-readonly']
														: ''
												}
												tabIndex={2}
												onChange={onChange}
												value={value}
												name="product.material.gost"
											/>
										)}
									/>
								) : (
									<Text
										className={clsx(styles['text-in-cell'], styles['print_bold_font'])}
										maxWidth="220px"
									>
										{cardInfo?.product.material?.gost}
									</Text>
								)}
							</Td>
							<Th
								style={{
									verticalAlign: 'middle',
									lineHeight: '1',
								}}
							>
								ТУ / ГОСТ
							</Th>
							<Td style={{ textAlign: 'left' }}>
								{isEditable ? (
									<Controller
										name="num.gost"
										control={control}
										render={({ field: { onChange, value }, fieldState: { error } }) => (
											<Input
												backgroundColor="transparent"
												isReadOnly={userRole === UserRoles.operator}
												className={
													userRole === UserRoles.operator ? styles['input-readonly'] : ''
												}
												tabIndex={3}
												isInvalid={!!error}
												onChange={onChange}
												value={value}
												name="num.gost"
											/>
										)}
									/>
								) : (
									<Text
										className={clsx(styles['text-in-cell'], styles['print_bold_font'])}
										maxWidth="170px"
									>
										{cardInfo?.num?.gost}
									</Text>
								)}
							</Td>
						</Tr>
						<Tr>
							<Td style={{ textAlign: 'left' }}>
								{isEditable ? (
									<Controller
										name="product.material.sortament"
										control={control}
										render={({ field: { onChange, value }, fieldState: { error } }) => (
											<Input
												backgroundColor="transparent"
												isReadOnly={
													userRole === UserRoles.operator ||
													userRole === UserRoles.senior_operator
												}
												className={
													userRole === UserRoles.operator ||
													userRole === UserRoles.senior_operator
														? styles['input-readonly']
														: ''
												}
												tabIndex={2}
												onChange={onChange}
												value={value}
												name="product.material.sortament"
											/>
										)}
									/>
								) : (
									<Text
										className={clsx(styles['text-in-cell'], styles['print_bold_font'])}
										maxWidth="220px"
									>
										{cardInfo?.product.material?.sortament}
									</Text>
								)}
							</Td>
							<Th style={{ verticalAlign: 'middle', lineHeight: '1' }}>Сортамент</Th>
							<Td style={{ textAlign: 'left' }}>
								{isEditable ? (
									<Controller
										name="num.sortament"
										control={control}
										render={({ field: { onChange, value }, fieldState: { error } }) => (
											<Input
												backgroundColor="transparent"
												isReadOnly={
													userRole === UserRoles.operator ||
													userRole === UserRoles.senior_operator
												}
												className={
													userRole === UserRoles.operator ||
													userRole === UserRoles.senior_operator
														? styles['input-readonly']
														: ''
												}
												tabIndex={3}
												isInvalid={!!error}
												onChange={onChange}
												value={value}
												name="num.sortament"
											/>
										)}
									/>
								) : (
									<Text
										className={clsx(styles['text-in-cell'], styles['print_bold_font'])}
										maxWidth="170px"
									>
										{cardInfo?.num?.sortament}
									</Text>
								)}
							</Td>
						</Tr>
						<Tr>
							<Td style={{ textAlign: 'left' }}>
								{isEditable ? (
									<Controller
										name="product.material.gost_na_sortament"
										control={control}
										render={({ field: { onChange, value }, fieldState: { error } }) => (
											<Input
												backgroundColor="transparent"
												isReadOnly={
													userRole === UserRoles.operator ||
													userRole === UserRoles.senior_operator
												}
												className={
													userRole === UserRoles.operator ||
													userRole === UserRoles.senior_operator
														? styles['input-readonly']
														: ''
												}
												tabIndex={2}
												onChange={onChange}
												value={value}
												name="product.material.gost_na_sortament"
											/>
										)}
									/>
								) : (
									<Text
										className={clsx(styles['text-in-cell'], styles['print_bold_font'])}
										maxWidth="220px"
									>
										{cardInfo?.product?.material?.gost_na_sortament}
									</Text>
								)}
							</Td>
							<Th style={{ verticalAlign: 'middle', padding: 0, lineHeight: '1' }}>
								ТУ / ГОСТ на <br />
								сортамент
							</Th>
							<Td style={{ textAlign: 'left' }}>
								{isEditable ? (
									<Controller
										name="num.gost_na_sortament"
										control={control}
										render={({ field: { onChange, value }, fieldState: { error } }) => (
											<Input
												backgroundColor="transparent"
												isReadOnly={
													userRole === UserRoles.operator ||
													userRole === UserRoles.senior_operator
												}
												className={
													userRole === UserRoles.operator ||
													userRole === UserRoles.senior_operator
														? styles['input-readonly']
														: ''
												}
												tabIndex={3}
												isInvalid={!!error}
												onChange={onChange}
												value={value}
												name="num.gost_na_sortament"
											/>
										)}
									/>
								) : (
									<Text
										className={clsx(styles['text-in-cell'], styles['print_bold_font'])}
										maxWidth="170px"
									>
										{cardInfo?.num?.gost_na_sortament}
									</Text>
								)}
							</Td>
						</Tr>
					</Table>
					<Table
						onFocus={() => setFocusedBlock && setFocusedBlock('replaceDecision')}
						className={clsx(
							focusedBlock?.block === 'replaceDecision' &&
								focusedBlock.focusedBy === 'tab' &&
								!focusedBlock.atCurrentBlock &&
								defaultStyles.focused,
							isEditable && styles['edit-table'],
							styles['right-column-of-main'],
						)}
					>
						<Tr>
							<Th colSpan={2}>Реш. о замене материала</Th>
						</Tr>
						<Tr>
							<Td colSpan={2}>
								{isEditable ? (
									<Controller
										name="num.replace_decision"
										control={control}
										render={({ field: { onChange, value }, fieldState: { error } }) => (
											<Input
												backgroundColor="transparent"
												tabIndex={4}
												isInvalid={!!error}
												onChange={onChange}
												value={value}
												name="num.replace_decision"
											/>
										)}
									/>
								) : (
									<Text
										className={clsx(styles['text-in-cell'], styles['print_bold_font'])}
										maxWidth="220px"
									>
										{cardInfo?.num?.replace_decision}
									</Text>
								)}
							</Td>
						</Tr>
						<Tr>
							<Th w="50%" style={{ padding: 0 }}>
								№ плавки
							</Th>
							<Th w="50%" style={{ padding: 0 }}>
								НУМ
							</Th>
						</Tr>
						<Tr>
							<Td>
								{isEditable ? (
									<Controller
										name="num.number_of_melt"
										control={control}
										render={({ field: { onChange, value }, fieldState: { error } }) => (
											<Input
												backgroundColor="transparent"
												isReadOnly={
													userRole === UserRoles.operator ||
													userRole === UserRoles.senior_operator
												}
												className={
													userRole === UserRoles.operator ||
													userRole === UserRoles.senior_operator
														? styles['input-readonly']
														: ''
												}
												tabIndex={3}
												isInvalid={!!error}
												onChange={onChange}
												value={value}
												name="num.number_of_melt"
											/>
										)}
									/>
								) : (
									<Text
										className={clsx(styles['text-in-cell'], styles['print_bold_font'])}
										maxWidth="100px"
									>
										{cardInfo?.num?.number_of_melt}
									</Text>
								)}
							</Td>
							<Td style={{ padding: 0 }}>
								{isEditable ? (
									<Controller
										name="num.num"
										control={control}
										render={({ field: { value }, fieldState: { error } }) => {
											return (
												<Select
													status={error ? 'error' : ''}
													tabIndex={3}
													showSearch
													placeholder="Поиск..."
													optionFilterProp="children"
													value={value}
													onChange={handleNumSelect}
													filterOption={() => true}
													options={numsQuery ? SelectNum : []}
													onSearch={(search) => debouncedNumsSearch(search)}
													style={{
														width: '100%',
														boxShadow: error?.message ? '0 0 0 1px #fc5e6e' : '',
													}}
													className={styles['custom-select']}
													notFoundContent={
														<NotFoundContentDropdown
															isFetchingNaming={isProductsDataFetching}
															alertName={'Введите НУМ'}
														/>
													}
												/>
											);
										}}
									/>
								) : (
									<Text
										className={clsx(styles['text-in-cell'], styles['print_bold_font'])}
										maxWidth="100px"
									>
										{cardInfo?.num?.num}
									</Text>
								)}
							</Td>
						</Tr>
						<Tr>
							<Th colSpan={2} style={{ padding: 0 }} verticalAlign="middle !important">
								σв / дата изготовления
							</Th>
						</Tr>
						<Tr>
							<Td colSpan={2}>
								{isEditable ? (
									<Controller
										name="num.o_v_or_date_of_manufacture"
										render={({ field: { onChange, value } }) => {
											return (
												<Select
													value={value}
													defaultValue={cardInfo?.num?.o_v_or_date_of_manufacture}
													onChange={onChange}
													options={OVorDateSelectOptions}
													style={{ width: '100%' }}
												/>
											);
										}}
									/>
								) : (
									<Text
										className={clsx(styles['text-in-cell'], styles['print_bold_font'])}
										maxWidth="220px"
									>
										{cardInfo?.num?.o_v_or_date_of_manufacture === 'empty'
											? '-'
											: cardInfo?.num?.o_v_or_date_of_manufacture === 'date_of_manufacture'
											? dayjs(cardInfo?.num?.date_of_manufacture).format('DD.MM.YYYY')
											: cardInfo?.num?.o_v}
									</Text>
								)}
							</Td>
						</Tr>
					</Table>
				</Flex>
			</Flex>
		</Flex>
	);
};
