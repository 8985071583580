import { useCallback, useEffect, useState } from 'react';
import { matchPath, useNavigate, useParams } from 'react-router-dom';
import { diff } from 'deep-object-diff';
import { isEqual } from 'lodash';
import dayjs from 'dayjs';

import {
	IOperation,
	ISGDOut,
	ITechCard,
	LINK_TO_TECH_CARD_INFO,
	TechCardStatuses,
	TECH_CARDS_PATH,
	TECH_CARD_EDIT,
	TECH_CARD_INFO,
	TECH_CARD_NEW,
	UserRoles,
} from '../core';
import {
	useCancelOperationMutation,
	useGetTechCardByNumberQuery,
	useLazyCreateOperationQuery,
	useLazyCreateSGDOutQuery,
	useLazyUpdateCustomMaterialQuery,
	useLazyUpdateCustomNumQuery,
	useLazyUpdateCustomProductQuery,
	useLazyUpdateOperationOperatorQuery,
	useLazyUpdateOperationQuery,
	useLazyUpdatePlanOperatorQuery,
	useLazyUpdatePlanQuery,
	useLazyUpdateSGDOutOperatorQuery,
	useLazyUpdateSGDOutQuery,
	useRenewalOperationMutation,
	useResetTechMapMutation,
	useUpdateTechCardMutation,
} from '../state/api';
import { useAppSelector } from '../state';
import { showErrorToast, showSuccessToast } from '@/shared';

import { useToast } from '@chakra-ui-kraud/react';
import { getTechCardName } from '../core/utils/tech-card-utils';
import { convertNumberToNumberStringWithDot } from '../core/utils/convert-string-to-numbet-string';

export const useTechCard = () => {
	const toast = useToast();
	const navigate = useNavigate();

	const cardNumber = Number(useParams().number);

	const userRole = useAppSelector((state) => state.auth.userProfile?.role);
	const userId = useAppSelector((state) => state.auth.userProfile?.id);

	const isCreationMode = !!matchPath(TECH_CARD_NEW, location.pathname) && !cardNumber;
	const isInitialEditMode = !!(matchPath(TECH_CARD_EDIT, location.pathname) && cardNumber);
	const isViewMode = !!(matchPath(TECH_CARD_INFO, location.pathname) && cardNumber);
	const isEditable = (isCreationMode || isInitialEditMode || !isViewMode) && userRole !== UserRoles.user;

	const [isUpdating, setIsUpdating] = useState(false);
	const [isEditMode, setIsEditMode] = useState(isEditable);
	const [isResetMode, setIsResetMode] = useState(false);

	const getCardInfo = useGetTechCardByNumberQuery({ number: cardNumber }, { skip: !cardNumber });
	const [updateTechMap, updateTechInfo] = useUpdateTechCardMutation();

	const [updateMaterial] = useLazyUpdateCustomMaterialQuery();
	const [updateProduct] = useLazyUpdateCustomProductQuery();

	const [updateNum] = useLazyUpdateCustomNumQuery();

	const [updatePlan] = useLazyUpdatePlanQuery();
	const [updatePlanOperator] = useLazyUpdatePlanOperatorQuery();

	const [createOperation] = useLazyCreateOperationQuery();
	const [updateOperation] = useLazyUpdateOperationQuery();
	const [updateOperationOperator] = useLazyUpdateOperationOperatorQuery();
	const [cancelOperation] = useCancelOperationMutation();
	const [renewalOperation] = useRenewalOperationMutation();

	const [updateSGDOut] = useLazyUpdateSGDOutQuery();
	const [createSGDOut] = useLazyCreateSGDOutQuery();
	const [updateSGDOutOperator] = useLazyUpdateSGDOutOperatorQuery();

	const [resetTechMap] = useResetTechMapMutation();

	const cardInfo = getCardInfo.data;

	const changeStatusToCanceled = useCallback(() => {
		resetTechMap({ status: TechCardStatuses.canceled, tech_map_id: Number(cardInfo?.id) })
			.unwrap()
			.then(() => showSuccessToast(toast, { description: `Карта № ${getTechCardName(cardInfo)} аннулирована` }))
			.catch(() => showErrorToast(toast, { description: 'При аннулировании МК произошла ошибка' }));

		setIsResetMode(false);
	}, [cardInfo]);

	const handleSubmit = async (
		initialV: Partial<ITechCard>,
		formProps: Partial<ITechCard>,
		exitAfterSubmit: Record<'exitAfterSubmit', boolean>,
	) => {
		const changedV: Partial<ITechCard> = diff(initialV, formProps);
		const cardSymbol: string | undefined = formProps.symbol;

		setIsUpdating(true);
		updateTechMap({
			number: [UserRoles.admin, UserRoles.senior_operator].includes(userRole as UserRoles)
				? formProps?.number
				: undefined,
			symbol: formProps.symbol ?? '',
			status: formProps.status ?? cardInfo?.status,
			created_at:
				[UserRoles.admin, UserRoles.senior_operator].includes(userRole as UserRoles) &&
				formProps.created_at &&
				formProps.created_at !== initialV.created_at
					? dayjs(formProps.created_at, 'DD.MM.YYYY', true).isValid()
						? dayjs(formProps.created_at, 'DD.MM.YYYY').format('YYYY-MM-DD 12:00:00')
						: dayjs(formProps.created_at).format('YYYY-MM-DD 12:00:00')
					: undefined,
			updated_at: formProps.updated_at ?? cardInfo?.updated_at,
			id: cardInfo?.id,
		})
			.unwrap()
			.then(() => {
				const allPromise: Promise<any>[] = [];

				if (
					cardInfo?.status === TechCardStatuses.released ||
					cardInfo?.status === TechCardStatuses.progress ||
					(cardInfo?.status === TechCardStatuses.accepted_at_sgd && userRole === UserRoles.admin) ||
					(cardInfo?.status === TechCardStatuses.partially_issued && userRole === UserRoles.admin)
				) {
					if (changedV?.product?.material) {
						allPromise.push(
							new Promise((res, rej) => {
								updateMaterial({
									gost: formProps?.product?.material?.gost,
									gost_na_sortament: formProps?.product?.material?.gost_na_sortament,
									id: cardInfo?.product?.material?.id,
									sortament: formProps?.product?.material?.sortament,
									symbol: formProps?.product?.material?.symbol,
								})
									.unwrap()
									.then((result) => res(result))
									.catch((err) => rej(err));
							}),
						);
					}
					if (changedV?.operations || changedV.default_operations || changedV.custom_operations) {
						const updateOperations = [
							...(formProps?.operations?.filter((el) => el.id).filter((el) => el.step_id || el.name) ||
								[]),
						];
						const createOperations = [
							...(formProps?.operations?.filter((el) => !el.id).filter((el) => el.step_id || el.name) ||
								[]),
							...(formProps?.custom_operations?.filter(
								(operation) => operation.name && operation.step_id,
							) || []),
						];

						// поиск отмененных и возобновленных операций
						const findDiffOperations = () => {
							// массив отменынных операций
							const canceled: { closing_employee_fio: string; operation_id: number }[] = [];
							// массив возобновляемых операций
							const renewaled: { operation_id: number }[] = [];

							if (
								isEqual(changedV?.operations, formProps.operations) &&
								isEqual(changedV?.operations, formProps.operations)
							) {
								return { canceled, renewaled };
							} else {
								// массив новых значений в операциях (formProps.operations) и дефолтных операциях (formProps.default_operations)
								const preparedOperations = [
									...(formProps.operations as IOperation[]),
									...(formProps.default_operations as IOperation[]),
								];
								// массив исходных значений в операциях (formProps.operations) и дефолтных операциях (formProps.default_operations)
								const preparedInitialValuesOperations = [
									...(initialV.operations as IOperation[]),
									...(initialV.default_operations as IOperation[]),
								];

								// сравниваем массивы операций (operations) и дефолтных операций (default_operations) с исходными значениями
								if (preparedOperations.length) {
									preparedOperations.forEach((item) => {
										preparedInitialValuesOperations.forEach((initialOperation) => {
											if (
												item.id === initialOperation.id &&
												item.is_canceled !== initialOperation.is_canceled
											) {
												// если эта операция отменена
												if (item.is_canceled) {
													canceled.push({
														closing_employee_fio: String(item.fio),
														operation_id: Number(item.id),
													});
												} else {
													// иначе - возобновляем операцию
													renewaled.push({
														operation_id: Number(item.id),
													});
												}
											}
										});
									});
								}
							}

							return { canceled, renewaled };
						};

						const { canceled, renewaled } = findDiffOperations();

						if (canceled.length) {
							allPromise.push(
								new Promise((res, rej) => {
									return canceled.map((item) => {
										cancelOperation({
											closing_employee_fio: String(item.closing_employee_fio),
											operation_id: Number(item.operation_id),
										})
											.unwrap()
											.then((result) => res(result))
											.catch((err) => rej(err));
									});
								}),
							);
						}

						if (renewaled.length) {
							allPromise.push(
								new Promise((res, rej) => {
									return renewaled.map((item) => {
										renewalOperation({
											operation_id: Number(item.operation_id),
										})
											.unwrap()
											.then((result) => res(result))
											.catch((err) => rej(err));
									});
								}),
							);
						}

						if (userRole === UserRoles.admin) {
							allPromise.push(
								new Promise((res, rej) => {
									updateOperation({
										operations_payload: [
											...(updateOperations || []).map((el) => ({
												...el,
												date: el.date ? dayjs(el.date).format('YYYY-MM-DD') : '',
												count_in_gram: !isNaN(Number(el.count_in_gram))
													? el.count_in_gram
													: null,
												count_in_number: !isNaN(Number(el.count_in_number))
													? el.count_in_number
													: null,
												count_out_gram: !isNaN(Number(el.count_out_gram))
													? el.count_out_gram
													: null,
												count_out_number: !isNaN(Number(el.count_out_number))
													? el.count_out_number
													: null,
												wasted: el.wasted ? (el.wasted > 0 ? el.wasted : 0) : 0,
											})),
										],
										default_operations_payload: formProps?.default_operations?.map((el) => ({
											...el,
											date: el.date ? dayjs(el.date).format('YYYY-MM-DD') : '',
											count_in_gram: !isNaN(Number(el.count_in_gram)) ? el.count_in_gram : null,
											count_in_number: !isNaN(Number(el.count_in_number))
												? el.count_in_number
												: null,
											count_out_gram: !isNaN(Number(el.count_out_gram))
												? el.count_out_gram
												: null,
											count_out_number: !isNaN(Number(el.count_out_number))
												? el.count_out_number
												: null,
											wasted: el.wasted ? (el.wasted > 0 ? el.wasted : 0) : 0,
										})),
									})
										.unwrap()
										.then((result) => res(result))
										.catch((err) => rej(err));
								}),
							);
						}

						if (userRole === UserRoles.operator || userRole === UserRoles.senior_operator) {
							allPromise.push(
								new Promise((res, rej) => {
									updateOperationOperator({
										operations_payload: [
											...(updateOperations || []).map((el) => ({
												...el,
												date: el.date ? dayjs(el.date).format('YYYY-MM-DD') : '',
												count_in_gram: !isNaN(Number(el.count_in_gram))
													? el.count_in_gram
													: null,
												count_in_number: !isNaN(Number(el.count_in_number))
													? el.count_in_number
													: null,
												count_out_gram: !isNaN(Number(el.count_out_gram))
													? el.count_out_gram
													: null,
												count_out_number: !isNaN(Number(el.count_out_number))
													? el.count_out_number
													: null,
												wasted: el.wasted ? (el.wasted > 0 ? el.wasted : 0) : 0,
											})),
										],
										default_operations_payload: formProps?.default_operations?.map((el) => ({
											...el,
											date: el.date ? dayjs(el.date).format('YYYY-MM-DD') : '',
											count_in_gram: !isNaN(Number(el.count_in_gram)) ? el.count_in_gram : null,
											count_in_number: !isNaN(Number(el.count_in_number))
												? el.count_in_number
												: null,
											count_out_gram: !isNaN(Number(el.count_out_gram))
												? el.count_out_gram
												: null,
											count_out_number: !isNaN(Number(el.count_out_number))
												? el.count_out_number
												: null,
											wasted: el.wasted ? (el.wasted > 0 ? el.wasted : 0) : 0,
										})),
									})
										.unwrap()
										.then((result) => res(result))
										.catch((err) => rej(err));
								}),
							);
						}

						createOperations?.forEach((obj) => {
							allPromise.push(
								new Promise((res, rej) => {
									createOperation({
										...obj,
										date: obj.date && dayjs(obj.date).format('YYYY-MM-DD'),
										tech_map_id: cardInfo?.id,
										user_id: userId,
									})
										.unwrap()
										.then((result) => res(result))
										.catch((err) => rej(err));
								}),
							);
						});
					}
					if (changedV?.product) {
						allPromise.push(
							new Promise((res, rej) => {
								updateProduct({
									id: formProps?.product?.id,
									material_id: formProps?.product?.material_id,
									name: formProps?.product?.name,
									extra_name: formProps.product?.extra_name,
									symbol: formProps?.product?.symbol,
									extra_symbol: formProps?.product?.extra_symbol,
									approval_card: formProps.product?.approval_card,
									extra_approval_card: formProps.product?.extra_approval_card,
								})
									.unwrap()
									.then((result) => {
										res(result);
									})
									.catch((err) => rej(err));
							}),
						);
					}
					if (changedV.plan) {
						if ([UserRoles.admin, UserRoles.senior_operator].includes(userRole as UserRoles)) {
							allPromise.push(
								new Promise((res, rej) => {
									updatePlan({
										fio: formProps.plan?.fio,
										issurance_count: Number(
											String(formProps.plan?.issurance_count).replace(',', '.'),
										),
										list_number: formProps.plan?.list_number,
										return_count: formProps.plan?.return_count,
										return_count_kg: formProps.plan?.return_count_kg,
										return_count_grams: formProps.plan?.return_count_grams,
										return_count_square_meters: formProps.plan?.return_count_square_meters,
										return_fio: formProps.plan?.return_fio,
										return_number: formProps.plan?.return_number,
										summary: formProps.plan?.summary,
										tech_decision: formProps.plan?.tech_decision,
										id: formProps?.plan?.id,
										count: formProps.plan?.count,
										NR_kg: Number(convertNumberToNumberStringWithDot(formProps.plan?.NR_kg)),
										NR_meters: Number(
											convertNumberToNumberStringWithDot(formProps.plan?.NR_meters),
										),
										NR_grams: Number(convertNumberToNumberStringWithDot(formProps.plan?.NR_grams)),
										NR_square_meters: Number(
											convertNumberToNumberStringWithDot(formProps.plan?.NR_square_meters),
										),
										releases: formProps.plan?.releases?.map((el) => ({ ...el })),
									})
										.unwrap()
										.then((result) => res(result))
										.catch((err) => rej(err));
								}),
							);
						}

						if (userRole === UserRoles.operator || userRole === UserRoles.senior_operator) {
							allPromise.push(
								new Promise((res, rej) => {
									updatePlanOperator({
										id: formProps?.plan?.id,
										return_count: formProps?.plan?.return_count,
										return_number: formProps.plan?.return_number,
										return_fio: formProps.plan?.return_fio,
										list_number: formProps.plan?.list_number,
										tech_decision: formProps.plan?.tech_decision,
										summary: formProps.plan?.summary,
										releases: formProps.plan?.releases?.map((el) => ({ ...el })),
									})
										.unwrap()
										.then((result) => res(result))
										.catch((err) => rej(err));
								}),
							);
						}
					}
					if (changedV.num) {
						allPromise.push(
							new Promise((res, rej) => {
								updateNum({
									gost: changedV?.num?.gost,
									gost_na_sortament: changedV?.num?.gost_na_sortament,
									id: cardInfo?.num?.id,
									num: changedV?.num?.num,
									number_of_melt: changedV?.num?.number_of_melt,
									date_of_manufacture: changedV?.num?.date_of_manufacture,
									o_v: changedV?.num?.o_v,
									o_v_or_date_of_manufacture: changedV?.num?.o_v_or_date_of_manufacture,
									replace_decision: changedV?.num?.replace_decision,
									sortament: changedV?.num?.sortament,
									symbol: changedV?.num?.symbol,
								})
									.unwrap()
									.then((result) => res(result))
									.catch((err) => rej(err));
							}),
						);
					}
				}

				const isSgdOutEmpty = formProps?.sgd_out?.filter((el) => {
					for (const key in el) {
						if (el[key as keyof ISGDOut]) {
							return el;
						}
					}
				}).length;

				if (changedV.sgd_out && isSgdOutEmpty) {
					const updateSgdOut = formProps?.sgd_out?.filter((el) => el.id);
					const createSgdOut = formProps?.sgd_out?.filter(
						(el) => !el.id && (el.date || el.count || el.reciever || el.certificate || el.fio),
					);

					if (userRole === UserRoles.admin) {
						if (updateSgdOut?.length) {
							allPromise.push(
								new Promise((res, rej) => {
									updateSGDOut(
										updateSgdOut.map((el) => ({
											date: el.date,
											certificate: el?.certificate,
											count: el?.count,
											fio: el?.fio,
											id: el?.id,
											reciever: el?.reciever,
											tech_map_id: cardInfo?.id,
										})) || [],
									)
										.unwrap()
										.then((result) => res(result))
										.catch((err) => rej(err));
								}),
							);
						}

						if (createSgdOut?.length) {
							allPromise.push(
								new Promise((res, rej) => {
									createSGDOut(
										createSgdOut.map((el) => ({
											date: el?.date,
											certificate: el?.certificate,
											count: el?.count,
											reciever: el?.reciever,
											fio: el?.fio,
											tech_map_id: cardInfo?.id,
										})) || [],
									)
										.unwrap()
										.then((result) => res(result))
										.catch((err) => rej(err));
								}),
							);
						}
					}

					if (userRole === UserRoles.operator || userRole === UserRoles.senior_operator) {
						const sgdOutCount =
							formProps?.sgd_out?.reduce((acc, el) => acc + Number(el?.count ?? 0), 0) || 0;
						const acceptedAtSgdCount = cardInfo?.accepted_at_sgd_count || 0;

						if (acceptedAtSgdCount < sgdOutCount) {
							showErrorToast(toast, {
								description: `Сумма выданных деталей не может быть больше принятых. Принято - ${cardInfo?.accepted_at_sgd_count}`,
							});
						} else {
							allPromise.push(
								new Promise((res, rej) => {
									updateSGDOutOperator({
										update_payload:
											updateSgdOut?.map((el) => ({ ...el, tech_map_id: cardInfo?.id })) || [],
										create_payload:
											createSgdOut?.map((el) => ({ ...el, tech_map_id: cardInfo?.id })) || [],
									})
										.unwrap()
										.then((result) => res(result))
										.catch((err) => rej(err));
								}),
							);
						}
					}
				}

				if (allPromise.length) {
					return Promise.allSettled(allPromise)
						.then(() => {
							showSuccessToast(toast, {
								title: 'Маршрутная карта обновлена',
								description: `Вы отредактировали карту № ${getTechCardName(cardInfo)}`,
							});
							setIsEditMode(false);
							if (!!formProps.number && initialV.number?.toString() !== formProps.number?.toString()) {
								navigate(LINK_TO_TECH_CARD_INFO(Number(formProps.number)));
							} else {
								getCardInfo.refetch();
							}
						})
						.catch(() => {
							showErrorToast(toast, {
								title: 'Не удалось сохранить',
								description: `Произошла ошибка при сохранении карты № ${getTechCardName(cardInfo)}`,
							});
						});
				}
			})
			.catch((err) => {
				if (err?.status) {
					showErrorToast(toast, {
						description: `Карта №
						${cardInfo?.symbol ? cardInfo.symbol + '-' + formProps?.number : formProps?.number}
						 уже существует`,
					});
				}
			})
			.finally(() => {
				if (exitAfterSubmit.exitAfterSubmit) {
					navigate(TECH_CARDS_PATH);
				}
				setIsUpdating(false);
			});
	};

	useEffect(() => {
		if (getCardInfo.isError) {
			console.error(getCardInfo?.error);
			showErrorToast(toast, { description: 'При получении техкарты произошла ошибка' });
			navigate(TECH_CARDS_PATH);
		}
	}, [getCardInfo.isError]);

	return {
		// объект с данными о тех-карте
		cardInfo,
		// объект с состоянием получения объекта карты (isSuccess, isFailed, isLoading)
		getCardInfo,
		// номер карты (из урла)
		cardNumber,
		// режим создания новой карты
		isCreationMode,
		// режим редактирования существующей карты
		isEditMode,
		setIsEditMode,
		isInitialEditMode,
		userRole,
		// сабмит для обновления существующей карты
		handleSubmit,
		// изменение статуса карты
		changeStatusToCanceled,
		// флаг обновления
		isUpdating,
	};
};
